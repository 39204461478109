import { useState } from "react";
import Sunny from "../../assets/images/Sunny.png";
import Rainy from "../../assets/images/Rainy.png";
import Mist from "../../assets/images/Mist.png";
import Snowy from "../../assets/images/Snowy.png";
import Cloudy from "../../assets/images/Cloudy.png";
import Drizzle from "../../assets/images/Drizzle.png";
import Night from "../../assets/images/Night.png";
import NightClear from "../../assets/images/Night-Clear.png";
import NightMist from "../../assets/images/Night-Mist.png";
import NightRainy from "../../assets/images/Night-Rainy.png";
import NightSnow from "../../assets/images/Night-Snow.png";

const apiKey = "3d208f3f769067e0813cfd313b28c6d5";
const apiUrl = "https://api.openweathermap.org/data/2.5/forecast?q=";

export default function Weather() {
  const [city, setCity] = useState("");
  const [error, setError] = useState(false);
  const [weatherData, setWeatherData] = useState(null);
  const [weatherImg, setWeatherImg] = useState(null);

  const handleSearch = async () => {
    const response = await fetch(`${apiUrl}${city}&appid=${apiKey}`);

    if (response.status === 404) {
      setError(true);
      setWeatherData(null);
    } else {
      const data = await response.json();
      setWeatherData(data);
      setError(false);
      console.log(data);

      const weatherMain = data.list[0].weather[0].main;
      const isNight = isNightTime(data.list[0].dt_txt);

      switch (weatherMain) {
        case "Clouds":
          setWeatherImg(isNight ? Night : Cloudy);
          break;
        case "Clear":
          setWeatherImg(isNight ? NightClear : Sunny);
          break;
        case "Rain":
          setWeatherImg(isNight ? NightRainy : Rainy);
          break;
        case "Drizzle":
          setWeatherImg(isNight ? NightRainy : Drizzle);
          break;
        case "Mist":
          setWeatherImg(isNight ? NightMist : Mist);
          break;
        case "Snow":
          setWeatherImg(isNight ? NightSnow : Snowy);
          break;
        default:
          setWeatherImg(null);
      }
    }
  };

  const isNightTime = (dateTime) => {
    const hour = new Date(dateTime).getHours();
    return hour >= 19 || hour < 5;
  };

  const handleInputChange = (e) => {
    setCity(e.target.value);
  };

  const sendOnKeyPress = (e) => {
    if (e.key === "Enter") {
      if (city.trim() !== "") {
        handleSearch();
      }
    }
  };

  const handleButtonClick = () => {
    handleSearch();
  };

  const weatherIconForDay = (index) => {
    if (index < weatherData.list.length) {
      const weatherMain = weatherData.list[index].weather[0].main;
      switch (weatherMain) {
        case "Clouds":
          return Cloudy;
        case "Clear":
          return Sunny;
        case "Rain":
          return Rainy;
        case "Drizzle":
          return Drizzle;
        case "Mist":
          return Mist;
        case "Snow":
          return Snowy;
        default:
          return null;
      }
    }
    return null;
  };

  const dayOfWeek = (index) => {
    if (weatherData.list && weatherData.list.length > index * 8) {
      const date = new Date(weatherData.list[index * 8].dt_txt);
      const dayOfWeek = date.toLocaleDateString(undefined, {
        weekday: "short",
      });
      return dayOfWeek.slice(0, 3);
    }
    return null;
  };

  return (
    <div className="main">
      <div className="card">
        <div className="search">
          <input
            type="text"
            spellCheck="false"
            value={city}
            placeholder="Search city..."
            onChange={handleInputChange}
            onKeyDown={sendOnKeyPress}
          />
          <button onClick={handleButtonClick}>
            <i className="fi fi-rr-search"></i>
          </button>
        </div>
        {error && (
          <div className="error">
            <p>Invalid city name</p>
          </div>
        )}
        {weatherData && (
          <div className="weather">
            <img src={weatherImg} alt="weather" className="weather-icon" />
            <h1 className="temp">
              {Math.round(weatherData.list[0].main.temp - 273.15)} °C
            </h1>
            <h2 className="city">{weatherData.city.name}</h2>
            <h4 className="humidity">
              Humidity: {weatherData.list[0].main.humidity}%
            </h4>
            <div className="weather-week">
              {[1, 2, 3, 4].map((index) => (
                <div key={index} className="weather-day">
                  <img
                    src={weatherIconForDay(index)}
                    alt="weather"
                    className="weather-icon"
                  />
                  <h5>{dayOfWeek(index)}</h5>
                  <p className="day">
                    {Math.round(weatherData.list[index].main.temp - 273.15)} °C
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
