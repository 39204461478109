import React from "react";
import ReactDOM from "react-dom/client";

import "./Styles/App.scss";
import { Weather } from "./Components/script";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Weather />
  </React.StrictMode>
);
